import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

class Accordion extends React.Component {
  render() {
    const { className, name, value, children } = this.props;

    return (
      <div className="accordion">
        <input type="checkbox" id={name} hidden />
        <label className={"accordion-header " + className} htmlFor={name}>
          <FontAwesomeIcon icon={faChevronRight} size="sm" className="icon" />
          {value}
        </label>
        <div className="accordion-body">
          {children}
        </div>
      </div>
    );
  }
}

Accordion.defaultProps = {
  className: '',
};


class AccordionCard extends React.Component {
  render() {
    const { name, title, children } = this.props;

    return (
      <div className="accordion card">
        <div className="card-body">
          <input type="checkbox" id={name} hidden />
          <label className="accordion-header h4 card-title" htmlFor={name}>
            <FontAwesomeIcon icon={faChevronRight} size="sm" className="icon" />
            {title}
          </label>
          <div className="accordion-body">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export { Accordion, AccordionCard };
