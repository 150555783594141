import React from 'react';
import axios from 'axios';
import 'assets/scss/komsek.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';

import { AccordionCard } from 'components/accordion';
import Footer from 'components/footer';
import Header from 'components/header';
import { EmailLink } from 'components/links';
import SEO from 'components/seo';

class Career extends React.Component {
  state = { loading: true }

  componentDidMount() {
    axios.post('/cockpit/api/collections/get/jobs', {
      filter: { visible: true },
      fields: { title: 1, code: 1, description: 1 },
      sort: { title: 1 },
    }).then(response => {
      this.setState({
        loading: false,
        data: response.data.entries,
      });
    }).catch(response => {
      this.setState({
        loading: false,
        data: null,
      });
    });
  }

  renderJobs = () => {
    const { loading, data } = this.state;

    if (loading) {
      return (
        <div className="loading loading-lg" />
      );
    } else if (data === null) {
      return (
        <>
          <h2>Volná místa</h2>
          <p>
            Při získávání dat nastala chyba&ensp;
            <FontAwesomeIcon icon={faBug} transform={{ rotate: 32 }} />.
            Zkuste to prosím později.
          </p>
        </>
      );
    } else {
      try {
        if (data.length > 0) {
          return (
            <>
              <h2 className="badge" data-badge={data.length}>Volná místa</h2>
              <div className="columns">
                {data.map(job => (
                  <div key={job._id} className="column col-12">
                    <AccordionCard name={job.code} title={job.title}>
                      {job.description
                        ? <div dangerouslySetInnerHTML={{ __html: job.description }} />
                        : <p><em>K této pozici nebyl přidán žádný popis.</em></p>
                      }
                      <EmailLink
                        to="prace" subject={`Pracovní pozice [${job.code}]`}
                        className="btn btn-primary d-block"
                      >Mám zájem</EmailLink>
                    </AccordionCard>
                  </div>
                ))}
              </div>
            </>
          );
        }
      } catch {
        return (
          <>
            <h2 className="badge" data-badge="?">Volná místa</h2>
            <p>Omlouváme se, ale při zpracovávání volných pracovních míst
              nastala chyba :(</p>
          </>
        );
      }
    }

    return null;
  }

  render () {
    return (
      <>
        <SEO title="Kariéra" />
        <Header />
        <article>
          <section>
            <div className="container grid-lg">
              <h1>Kariéra</h1>

              <h2>Proč pracovat u nás</h2>
              <div className="columns">
                <div className="column col-sm-12">
                  <h4>Zaměstnanecké výhody</h4>
                  <ul>
                    <li>Možnost pracovat v jediné církevní servisní organizaci</li>
                    <li>Přátelské prostředí a malé pracovní kolektivy</li>
                    <li>Možnost práce na plný i částečný pracovní úvazek</li>
                    <li>Výhodné mzdové podmínky</li>
                    <li>Možnost využití zaměstnanecké dotované stravy</li>
                    <li>Podpora v odborném růstu a dalším vzdělávání</li>
                    <li>Možnost pracovat na projektech a i v jiných oblastech</li>
                  </ul>
                </div>
                <div className="divider-vert hide-sm" />
                <div className="column col-sm-12">
                  <h4>Balíček benefitů</h4>
                  <ul>
                    <li>Příspěvek na stravování</li>
                    <li>Možnost parkování v areálu</li>
                    <li>Odměny k pracovnímu výročí</li>
                    <li>Kulturní akce pořádané nemocnicí</li>
                    <li>5 týdnů dovolené</li>
                  </ul>
                </div>
              </div>

              {this.renderJobs()}
            </div>
          </section>
        </article>
        <Footer />
      </>
    );
  }
}

export default Career;
